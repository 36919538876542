const assertProcessEnv = require('assert-process-env');

const IS_CLIENT_SIDE = typeof window !== 'undefined';

const ENVIRONMENTS = [
	'beta',
	'development',
	'production',
	'staging',
	'staging-eu',
] as const;

const ENVIRONMENT = assertProcessEnv(
	'POD_NAMESPACE',
	(namespace: (typeof ENVIRONMENTS)[number]) => {
		if (!process.env.IS_SERVER_RUNTIME || IS_CLIENT_SIDE) return true;
		return ENVIRONMENTS.includes(namespace);
	},
) as (typeof ENVIRONMENTS)[number];

export const getEnvironment = () => ENVIRONMENT;
