import { getEnvironment } from './config.environment';

const assertProcessEnv = require('assert-process-env');

export const IS_CLIENT_SIDE = typeof window !== 'undefined';

// Webpack overrides NODE_ENV, so relying on it doesn't work for webpack builds.
// https://webpack.js.org/configuration/optimization/#optimizationnodeenv
// We can set `mode` in Webpack config, but this breaks the output of `next build`.
// https://github.com/vercel/next.js/issues/19001
// The only way to get a consistent value here is to use a new environment variable.
export const DEBUG =
	process.env.NODE_ENV !== 'production' || process.env.DEBUG === '1';

export const IS_PROD = process.env.NODE_ENV === 'production';

export const IS_BUILDING = process.env.BUILDING === 'true';

export const EXTERNAL_API_PORT: string =
	assertServerSideRuntimeEnv('EXTERNAL_API_PORT');

export const INTERNAL_API_PORT: string =
	assertServerSideRuntimeEnv('INTERNAL_API_PORT');

export const LAUNCHDARKLY_SDK_KEY: string = assertServerSideRuntimeEnv(
	'LAUNCHDARKLY_SDK_KEY',
);

export const JWT_SECRET_KEY: string =
	assertServerSideRuntimeEnv('JWT_SECRET_KEY');

export const CONTENTFUL_DELIVERY_API_KEY: string = assertServerSideRuntimeEnv(
	'CONTENTFUL_DELIVERY_API_KEY',
);

export const CONTENTFUL_PREVIEW_API_KEY: string = assertServerSideRuntimeEnv(
	'CONTENTFUL_PREVIEW_API_KEY',
);

export const ONAIR_CLIENT_JWT_SECRET: string = assertServerSideRuntimeEnv(
	'ONAIR_CLIENT_JWT_SECRET',
);

export const ONAIR_LINK_JWT_SECRET: string = assertServerSideRuntimeEnv(
	'ONAIR_LINK_JWT_SECRET',
);

export const ONAIR_SY_APP_SSR_JWT_SECRET: string = assertServerSideRuntimeEnv(
	'ONAIR_SY_APP_SSR_JWT_SECRET',
);

const host = {
	beta: 'https://beta.streamyard.com',
	production: 'https://streamyard.com',
	staging: 'https://staging.streamyard.com',
	'staging-eu': 'https://stagingeu.streamyard.com',
	development: '',
}[getEnvironment()];

export const PUBLIC_FOLDER_PREFIX: string = IS_PROD
	? `${host}/next-static/public`
	: '';

export const ONAIR_REDIS_PASSWORD: string = assertServerSideRuntimeEnv(
	'ONAIR_REDIS_PASSWORD',
);

export const ONAIR_REDIS_HOST: string =
	assertServerSideRuntimeEnv('ONAIR_REDIS_HOST');

export const ONAIR_REDIS_PORT: number =
	assertServerSideRuntimeEnv('ONAIR_REDIS_PORT');

export const REDIS_MAIN_HOST: string = assertServerSideRuntimeEnv('REDIS_HOST');

export const REDIS_MAIN_PASSWORD: string =
	assertServerSideRuntimeEnv('REDIS_PASSWORD');

export const REDIS_MAIN_PORT: number = assertServerSideRuntimeEnv('REDIS_PORT');

function assertServerSideRuntimeEnv(name: string) {
	return assertProcessEnv(name, (value: string | undefined) => {
		if (!process.env.IS_SERVER_RUNTIME || IS_CLIENT_SIDE) return true;
		return !!value;
	});
}

export const DEFAULT_META_DESCRIPTION =
	'StreamYard is a professional live streaming and recording studio in your browser. Record your content, or stream live to Facebook, YouTube, and other platforms.';
export const DEFAULT_META_TITLE = 'StreamYard';
