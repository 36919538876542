const mainBundleRegex =
	/<script type="text\/javascript" src="(\/static\/main\.*.*\.js)"/m;

const extractLinks = (html: string): string[] => {
	const result = [];
	const mainBundle = mainBundleRegex.exec(html)?.[1];

	if (mainBundle) result.push(mainBundle);

	return result;
};

export default extractLinks;
