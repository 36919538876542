import Head from 'next/head';
import { useEffect, useState } from 'react';
import extractLinks from './extractLinks';

export const Prefetch = () => {
	const [links, setLinks] = useState<Array<string>>([]);

	useEffect(() => {
		const fetchLinks = async () => {
			const host = window.location.host;
			const pageHtml = await fetch(`https://${host}`, {
				credentials: 'omit',
			}).then((res) => res.text());
			const prefetchLinks = extractLinks(pageHtml);
			setLinks(prefetchLinks);
		};

		const shouldRenderLinks = document.cookie.includes('_use_prefetch=1');
		if (shouldRenderLinks) {
			fetchLinks();
		}
	}, []);

	const prefetchTags = links.map((link) => (
		<link key={link} rel="prefetch" href={link} />
	));

	return <Head>{prefetchTags}</Head>;
};
