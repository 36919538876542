import React from 'react';
import Head from 'next/head';

const SocialMetadata = ({ article, og, twitter }: Props) => (
	<Head>
		{/* Article */}
		{article?.modifiedTime && (
			<meta
				property="article:modified_time"
				content={new Date(article.modifiedTime).toISOString()}
				key="article:modified_time"
			/>
		)}
		{article?.publishedTime && (
			<meta
				property="article:published_time"
				content={new Date(article.publishedTime).toISOString()}
				key="article:published_time"
			/>
		)}
		{article?.publisher && (
			<meta
				property="article:publisher"
				content={article.publisher}
				key="article:publisher"
			/>
		)}
		{/* Open Graph Protocol */}
		{og?.description && (
			<meta
				property="og:description"
				content={og.description}
				key="og:description"
			/>
		)}
		{og?.imgUrl && (
			<meta property="og:image" content={og.imgUrl} key="og:image" />
		)}
		{og?.imgAlt && (
			<meta property="og:image:alt" content={og.imgAlt} key="og:image:alt" />
		)}
		{og?.imgHeight && (
			<meta
				property="og:image:height"
				content={og.imgHeight.toString()}
				key="og:image:height"
			/>
		)}
		{og?.imgWidth && (
			<meta
				property="og:image:width"
				content={og.imgWidth.toString()}
				key="og:image:width"
			/>
		)}
		{og?.siteName && (
			<meta property="og:site_name" content={og.siteName} key="og:site_name" />
		)}
		{og?.title && (
			<meta property="og:title" content={og.title} key="og:title" />
		)}
		{og?.type && <meta property="og:type" content={og.type} key="og:type" />}
		{og?.url && <meta property="og:url" content={og.url} key="og:url" />}
		{/* Twitter */}
		{twitter?.card && (
			<meta name="twitter:card" content={twitter.card} key="twitter:card" />
		)}
		{twitter?.creator && (
			<meta
				name="twitter:creator"
				content={twitter.creator}
				key="twitter:creator"
			/>
		)}
		{twitter?.description && (
			<meta
				name="twitter:description"
				content={twitter.description}
				key="twitter:description"
			/>
		)}
		{twitter?.imgUrl && (
			<meta
				name="twitter:image"
				content={twitter?.imgUrl}
				key="twitter:image"
			/>
		)}
		{twitter?.imgAlt && (
			<meta
				name="twitter:image:alt"
				content={twitter.imgAlt}
				key="twitter:image:alt"
			/>
		)}
		{twitter?.site && (
			<meta name="twitter:site" content={twitter.site} key="twitter:site" />
		)}
		{twitter?.title && (
			<meta name="twitter:title" content={twitter.title} key="twitter:title" />
		)}
	</Head>
);

type Props = {
	article?: {
		modifiedTime?: string;
		publishedTime?: string;
		publisher?: string;
	};
	og?: {
		title?: string;
		description?: string;
		imgAlt?: string;
		imgUrl?: string;
		siteName?: string;
		type?: string;
		url?: string;
		imgHeight?: number;
		imgWidth?: number;
	};
	twitter?: {
		title?: string;
		description?: string;
		imgAlt?: string;
		imgUrl?: string;
		card?: string;
		creator: string;
		site?: string;
	};
};

export default SocialMetadata;
