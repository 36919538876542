/** @typedef {import('next-i18next').UserConfig} I18NUserConfig */

const path = require('path');

/** @type {I18NUserConfig & { localeDisplayNames: {[key: string]: string}}} */
module.exports = {
	// default is v4, v3 is required to match the _plural suffix of our translation files https://www.i18next.com/misc/json-format#i18next-json-v3
	compatibilityJSON: 'v3',
	i18n: {
		defaultLocale: 'en',
		localeDetection: false,
		locales: ['en', 'es', 'pt', 'ja', 'fr', 'it', 'de', 'vi', 'th'],
	},
	// TODO SSR get locale path from a less brittle place, maybe move localizations to its own package?
	localePath: path.join(
		__dirname,
		'node_modules',
		'@sy',
		'app-client',
		'src',
		'static',
		'locales',
	),
	localeDisplayNames: {
		en: 'English',
		es: 'Español',
		pt: 'Português',
		ja: '日本語',
		fr: 'Français',
		it: 'Italiano',
		de: 'Deutsch',
		vi: 'Tiếng Việt',
		th: 'ภาษาไทย',
	},
};
